/* @jsx jsx */
import { jsx, Spinner } from "theme-ui";

import { Layout } from "../../components/Layout";
import { navigate } from "gatsby";
import { useGetCurrentUserQuery } from "../../graphql/queries/_generated";
import { useEffect } from "react";

const RedirectToFirstProject = () => {
  const [{ data, fetching }] = useGetCurrentUserQuery();
  useEffect(() => {
    if (data?.currentUser?.projects && data.currentUser.projects.length > 0) {
      navigate(`/app/${data.currentUser.projects[0].id}`);
    }
    if (!fetching && !data?.currentUser) {
      navigate(`/login`, {
        replace: true,
      });
    }
  }, [data?.currentUser, fetching]);

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Spinner size={24} color="brandBlue.2" />
    </div>
  );
};

export default () => {
  return (
    <Layout minimalFooter>
      <RedirectToFirstProject />
    </Layout>
  );
};
